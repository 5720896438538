export const EVENT = Object.freeze({
  SUBMIT: `suggestion-submit`,
  ERROR: `error-report`,
  STATISTICS: 'buzzni-statistics',
  SHOWHOST_VIDEO_PLAY: 'showhost-video-play',
  SHOWHOST_VIDEO_PAUSE: 'showhost-video-pause',
  SHOWHOST_ABORT: 'showhost_abort',
  SHOWHOST_AUTO_WELCOME: 'showhost_auto_welcome',
  SHOWHOST_NATIVE_CALL: 'showhost-native-call',
  SHOWHOST_CHANGE_BUTTON: 'showhost-change-button',
});
